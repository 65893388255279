// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

const Jurado = () => {
    return (
        <>
            <div id="Jurado" className="fondoComponents mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="juradoTitulo mb-5">Jurado</h1>
                        </div>
                        <swiper-container loop="true" class="mb-5" navigation="true"  slides-per-view={window.screen.width < 1024 ? "1" : "3"} >
                            <swiper-slide>
                                <div class="card mx-auto juradoCard">
                                    <div className="image-container">
                                        <img src="https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/MD-LOW.jpg" class="card-img-top mt-3" alt="..." />
                                    </div>
                                    <div class="card-body">
                                        <p className="juradoText text-center">Martha Debayle</p>
                                        {/*<p className="text-center">DIRECTORA DE MMK GROUP</p>*/}
                                        <p className="text-center">CEO y fundadora de MMK Group, una de las 50 Mujeres Poderosas de Forbes, forma parte de los 300 Líderes Mexicanos y creadora de power brands bajo su nombre, la primera emprendedora Endeavor en México.</p>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="card mx-auto juradoCard" >
                                    <div className="image-container">
                                        <img src="https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/MERCADOPAGOLOW.jpg" class="card-img-top mt-3" alt="..." />
                                    </div>
                                    <div class="card-body">
                                        <p className="juradoText text-center">ENRIQUE HORCASITAS</p>
                                        {/*<p className="text-center">DIRECTORA DE MMK GROUP</p>*/}
                                        <p className="text-center"> Director de pymes en Mercado Pago. Tiene un MBA en Business Administration and Management por la Escuela de Negocios de la Universidad de Navarra, España y fue fundador de Coru.com.</p>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="card mx-auto juradoCard" >
                                    <div className="image-container ">
                                        <img src="https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/DENNIS-STEVENS-2.png" class="dennis card-img-top mt-3 " alt="..." />
                                    </div>
                                    <div class="card-body">
                                        <p className="juradoText text-center">DENNIS STEVENS</p>
                                        {/*<p className="text-center">DIRECTORA DE MMK GROUP</p>*/}
                                        <p className='text-center'>Inversionista en empresas de tecnología y hospitalidad. Fundador de Secure Corp, empresa de seguridad; Restaurante Nostos; Centiva, empresa de incentivos; Screen Cast, Retail Media; Pistorius, fabricante de Drones Estratégicos.</p>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="card mx-auto juradoCard" >
                                    <div className="image-container ">
                                        <img src="https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/scot-rank.jpg" class="dennis card-img-top mt-3 " alt="..." />
                                    </div>
                                    <div class="card-body">
                                        <p className="juradoText text-center">Scot Rank</p>
                                        {/*<p className="text-center">DIRECTORA DE MMK GROUP</p>*/}
                                        <p className='text-center'>Economista por la UCLA tiene un MBA en management por la misma institución. Fue CEO de Walmart y Director General de Lala, además es inversionista y experto en productos de consumo y venta minorista.
                                        </p>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper-container>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Jurado;