import Formulario from "./Formulario";

const Home = () => {
    return (
        <>
            <div id="home" className="fondoProximamente">

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center mt-5 p-5">
                            <img src="https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/EnchulameMercadoPago.png" className="logoimgHome text-center" />
                            <h5 className="text-center text-light mt-4 pb-3">DGRTC/5087/2024</h5>
                        </div>
                        <div className="col-12 text-center mb-5">
                            <button type="button" className="btn btnRegistro  mx-auto" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                REGISTRARME
                            </button>
                        </div>
                        
                        <div className="col-12 col-sm-4 text-center">
                            <img src="https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/ICONO1.png" className="iconosHome" />
                            <p className="textoHome"><strong>PASO 1:</strong> <p className="parrafoHome">REGÍSTRATE</p></p>
                        </div>
                        <div className="col-12 col-sm-4 text-center">
                            <img src="https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/ICONO2.png" className="iconosHome" />
                            <p className="textoHome"><strong>PASO 2:</strong> <p className="parrafoHome">PARTICIPA</p></p>
                        </div>
                        <div className="col-12 col-sm-4 text-center mb-5">
                            <img src="https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/ICONO3.png" className="iconosHome1" />
                            <p className="textoHome"><strong>PASO 3:</strong> <p className="parrafoHome">ESPERA A LA SELECCIÓN</p></p>
                        </div>
                    </div>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <Formulario />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Home;